import React, {useContext} from 'react';
import './DriverSignUp.scss';
import CountryContext from "../../context/CountryContext";
import ButtonScroll from '../../components/ButtonScroll'
import { injectIntl, FormattedMessage } from "gatsby-plugin-intl"

const DriverSignUp = (props) => {
	const Context = useContext(CountryContext)
	return (
		<div className='driver-earnMoney'>
			<img
				className="driver-earnMoney__image d-block d-sm-none"
				src={`../../../${Context.country}/images/img4_mobile.png`}
			/>
			<img
				className="driver-earnMoney__image d-none d-sm-block"
				src={`../../../${Context.country}/images/img4.png`}
			/>
			<div className="driver-earnMoney__content">
				<div>
					<h1>
						<FormattedMessage 
							id="home.driverSignUp.title"
							values={{ br: <br />  }}
						/>
					</h1>
					<ButtonScroll keyWord={props.intl.formatMessage({id: "button.becomeADriver"})}/>
				</div>
			</div>
		</div>
	)
}


export default injectIntl(DriverSignUp)