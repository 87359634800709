import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';

const HImageByPath = props => {
  const {
    size,
    filename,
    imgStyle = {},
    noBlur = false
  } = props || {};

  const data = useStaticQuery(graphql`
	query {
    images: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 4000,srcSetBreakpoints: [ 400, 576, 768, 992, 1200, 1440 ]) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    imagesNoBase64: allFile {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid(maxWidth: 4000,srcSetBreakpoints: [ 400, 576, 768, 992, 1200, 1440 ]) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
  `)

  const image = noBlur
    ? data.imagesNoBase64.edges.find(n => {
      return n.node.relativePath.includes(filename);
    })
    : data.images.edges.find(n => {
      return n.node.relativePath.includes(filename);
    });

  if (!image) return null;

  return <Img
    {...props}
    fluid={image.node.childImageSharp.fluid}
    imgStyle={imgStyle}
  />;
};

export default HImageByPath;