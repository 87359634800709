import React, { Component } from 'react';
import { Carousel, Row, Col } from 'react-bootstrap';
import VisibilitySensor from "react-visibility-sensor";

import HImage from './../../components/HImage/HImageByPath';

import LeftQuoteIcon from './../../assets/images/left-quote-icon.svg';
import NextPage from './../../assets/images/next-page.svg';

class SpotLightCarousel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 0
        }
    }

    componentDidMount() {
        this.handleChangeActiveIndex(0);
    }

    handleChangeActiveIndex = (activeIndex) => {
        this.setState({
            activeIndex
        })
    }

    renderCarouselItem = (itemData, index) => {
        const {
            name,
            describ,
            companylocation,
            quote,
            image
        } = itemData || {};

        const imgPath = !image ? 'noImage.png' : 'SpotLight/' + image;

        return <Carousel.Item key={index}>
            <Row>
                <Col md={6} xs={12} className="h-carousel-quote__img">
                    <HImage
                        filename={imgPath}
                    />
                </Col>
                <Col md={6} xs={12} className="h-carousel-quote__content">
                    <div className="h-carousel-quote__body">
                        <div className="h-carousel-quote__quote">
                            <LeftQuoteIcon />
                            <p>{
                                quote
                            }</p>
                        </div>
                        <div className="h-carousel-quote__describ">
                            <p className="h-carousel-quote__describ--name">{
                                name
                            }</p>
                            <p className="h-carousel-quote__describ--describ">{
                                describ
                            }</p>
                            <p className="h-carousel-quote__describ--location">{
                                companylocation
                            }</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Carousel.Item>
    }

    renderIcon = (key) => {
        return <div className={`h-carousel-quote__icon h-carousel-quote__icon--${key}`}>
            <NextPage />
        </div>
    }

    render() {
        const { data = [] } = this.props || {};

        const {
            activeIndex = 0
        } = this.state || {};

        return (
            <div className="h-carousel-quote">
                <VisibilitySensor
                    onChange={(isVisible) => {
                        if (isVisible)
                            this.handleChangeActiveIndex(activeIndex === (data || []).length - 1 ? 0 : activeIndex + 1)
                    }}
                    intervalCheck={false}
                    scrollCheck={true}
                    scrollDelay={250}
                    resizeCheck={true}
                    resizeDelay={250}
                    partialVisibility={true}
                >
                    {
                        ({ isVisible }) =>
                            <Carousel
                                className="h-carousel-quote__carousel"
                                activeIndex={activeIndex}
                                interval={isVisible ? 5000 : null}
                                onSelect={this.handleChangeActiveIndex}
                                fade={true}
                                pauseOnHover={false}
                                prevIcon={this.renderIcon('prev')}
                                nextIcon={this.renderIcon('next')}
                            >
                                {
                                    (data || []).map((item, index) => {
                                        return this.renderCarouselItem(item, index)
                                    })
                                }
                            </Carousel>
                    }
                </VisibilitySensor>
            </div>
        );
    }
}

export default SpotLightCarousel;