import React, { Component } from 'react';

import { ContextConSumer } from './index';
import SpotLight from '../containers/SpotLight/SpotLight';
// import SignUp from '../containers/SignUp/SignUp';

import DriverSignUp from  '../containers/DriverSignUp/DriverSignUp';

import WaveBack from './../assets/images/waveBack.svg';

class SignLayout extends Component {
    render() {
        const {
            children = null,
            spotLight = true,
            signUp = true
        } = this.props || {};
        return (
            <>
                {
                    children
                }
                {/* <ContextConSumer>
                    {
                        props => <>
                            {
                                signUp && <DriverSignUp
                                    {...props}
                                />
                            }
                        </>
                    }
                </ContextConSumer> */}
            </>
        );
    }
}

export default SignLayout;