import React, { Component } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import SpotLightCarousel from './SpotLightCarousel';

import './SpotLight.scss';

class SpotLight extends Component {
    render() {
        const { spotLight } = this.props || {};
        const childSpotLightJson = !spotLight
            || !spotLight.node
            || !spotLight.node.childSpotLightJson ? {}
            : spotLight.node.childSpotLightJson;

        const {
            title,
            describ,
            quotes = []
        } = childSpotLightJson || {};

        const carouselData = (quotes || []).filter(quote => quote.showQuote);

        return (
            <section className="h-section-present h-section-present__spotlight">
                <h2>{
                    title
                }</h2>
                <p>{
                    describ
                }</p>
                <SpotLightCarousel
                    data={carouselData}
                />
            </section>
        )
    }
}

export default (props) => {
    const { locale = 'en' } = props || {};
    const query = graphql`
    query SpotLight {
        spotLightJson: allFile(
        filter: { relativeDirectory: { eq: "spotLight" } }
        ) {
        edges {
            node {
                relativePath
                name
                childSpotLightJson {
                        title
                        describ
                        quotes {
                            name
                            describ
                            companylocation
                            quote
                            image
                            showQuote
                        }
                    }
            }
        }
        }
    }
    `;

    const data = useStaticQuery(query);

    const spotLight = !data
        || !data.spotLightJson
        || !data.spotLightJson.edges
        ? {}
        : data.spotLightJson.edges.find(n => {
            return n.node.name.includes(locale);
        });

    return <SpotLight
        spotLight={spotLight}
    />
};

